import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import Notifications from '@kyvg/vue3-notification'

createApp(App)
    .use(router)
    .use(VueSweetalert2)
    .use(Notifications)
    .mount('#app')
