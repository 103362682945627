import { createWebHistory, createRouter } from "vue-router"
import Genny from "@/views/Genny.vue"

const routes = [
    {
        path: "/",
        name: "Genny",
        component: Genny
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(() => {
    document.title = 'Genny';
})


export default router