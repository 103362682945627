<template>
  <loading v-model:active="isLoading" color="#198754" :width="100" :height="100" :opacity="0.7" loader="spinner" />

  <div class="container mt-5 mb-5">
    <div class="row">
      <div class="col-6 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <label for="minusWords" class="form-label">Минус-слова</label>
              <div class="form-text">Пример: -слово1 -слово2 -слово3 ...</div>
              <input v-model='data.minusWords.value' v-on:keyup="charCount($event, data.minusWords)"
                :maxlength="data.minusWords.maxLength" type="text" class="form-control" id="minusWords">
              <div class="form-text"> {{ data.minusWords.length }} / {{ data.minusWords.maxLength }}</div>
            </div>
            <div class="mb-3">
              <label for="minusWords" class="form-label">Данные из уникализатора</label>
              <div class="uniqueData" @paste="pasteFromClipboard"></div>
              <div class="form-text"> Наведите курсор на таблицу и вставьте через Ctrl+V 3 столбца</div>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="btn btn-primary mt-2" @click="clickClipboard">Вставить из буфера</button>
                <button class="btn btn-danger mt-2" @click="clearUnifier">Очистить данные</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-7">
                <label for="region" class="form-label">Регион</label>
                <input v-model='data.region.value' type="text" class="form-control" id="region">
              </div>
              <div class="mb-3 col-5">
                <label for="bid" class="form-label">Ставка</label>
                <input v-model='data.bid.value' @keypress="isNumber($event)" type="number " class="form-control"
                  id="bid">
              </div>
            </div>
            <div class="mb-3">
              <label for="title1" class="form-label">Заголовок 1</label>
              <input v-model='data.title1.value' v-on:keyup="charCount($event, data.title1)"
                :maxlength="data.title1.maxLength" type="text" class="form-control" id="title1">
              <div class="form-text"> {{ data.title1.length }} / {{ data.title1.maxLength }}</div>
            </div>
            <div class="mb-3">
              <label for="title2" class="form-label">Заголовок 2</label>
              <input v-model='data.title2.value' v-on:keyup="charCount($event, data.title2)"
                :maxlength="data.title2.maxLength" type="text" class="form-control" id="title2">
              <div class="form-text"> {{ data.title2.length }} / {{ data.title2.maxLength }}</div>
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Текст описания</label>
              <input v-model='data.description.value' v-on:keyup="charCount($event, data.description)"
                :maxlength="data.description.maxLength" type="text" class="form-control" id="description">
              <div class="form-text"> {{ data.description.length }} / {{ data.description.maxLength }}</div>
            </div>
            <div class="mb-3">
              <label for="displayedURL" class="form-label">Отображаемый URL</label>
              <input v-model='data.displayedURL.value' v-on:keyup="charCount($event, data.displayedURL)"
                :maxlength="data.displayedURL.maxLength" type="text" class="form-control" id="displayedURL">
              <div class="form-text"> {{ data.displayedURL.length }} / {{ data.displayedURL.maxLength }}</div>
            </div>
            <div class="mb-3">
              <label for="link" class="form-label">Ссылка</label>
              <textarea v-model='data.link.value' v-on:keyup="charCount($event, data.link)"
                :maxlength="data.link.maxLength" type="text" class="form-control" id="displayedURL"></textarea>
              <div class="form-text"> {{ data.link.length }} / {{ data.link.maxLength }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="h3">Дополнения</div>
        </div>
      </div>
      <div class="col-8 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="quicklink-message badge bg-info"></div><br>
            <div class="quicklink-message1 badge bg-info"></div>
            <div class="quicklink-items">
              <div v-for="(item, key) in data.quickLinks.items" :key="item"
                class="quicklink-item d-flex align-items-center">
                <div class="quicklink-number p-2"><b>БС{{ key + 1 }}</b></div>
                <div class="quicklink-group p-2">
                  <label for="link" class="form-label">Заголовок</label>
                  <input v-model='item.title.value' v-on:keyup="charCount($event, item.title)"
                    :maxlength="item.title.maxLength" type="text" class="form-control" id="displayedURL">
                  <div class="form-text"> {{ item.title.length }} / {{ item.title.maxLength }}</div>
                </div>
                <div class="quicklink-group p-2">
                  <label for="link" class="form-label">Описание</label>
                  <input v-model='item.desc.value' v-on:keyup="charCount($event, item.desc)"
                    :maxlength="item.desc.maxLength" type="text" class="form-control" id="displayedURL">
                  <div class="form-text"> {{ item.desc.length }} / {{ item.desc.maxLength }}</div>
                </div>
                <div class="quicklink-group p-2">
                  <label for="link" class="form-label">Ссылка</label>
                  <input v-model='item.link.value' v-on:keyup="charCount($event, item.link)"
                    :maxlength="item.link.maxLength" type="text" class="form-control" id="displayedURL">
                  <div class="form-text"> {{ item.link.length }} / {{ item.link.maxLength }}</div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" @click="addQuickLinks" v-show="data.quickLinks.items.length != 8">
              Добавить 4 БС
            </button>
          </div>
        </div>
      </div>
      <div class="col-4 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="corrections-message badge bg-info"></div>
            <div v-for="(item, key) in data.corrections.items" :key="item" class="mb-3">
              <label for="correction{{ key+1 }}" class="form-label">Уточнение {{ key + 1 }}</label>
              <input v-model='item.value' v-on:keyup="charCount($event, item)" :maxlength="item.maxLength" type="text"
                class="form-control" id="correction{{ key+1 }}">
              <div class="form-text"> {{ item.length }} / {{ item.maxLength }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-success" @click="saveData">Сохранить</button>
          <button type="button" class="btn btn-danger" @click="clearData">Очистить данные</button>
        </div>
      </div>
      <div class="col-6 text-end">
        <button type="button" class="btn btn-outline-primary" @click="generate">Сгенерировать</button>
      </div>
      <div class="col-12 text-right mt-3">
        <div class="files"></div>
      </div>
    </div>
  </div>
  <notifications />
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Genny',
  data() {
    return {
      isLoading: false,
      requiredFields: [
        ['region', 'value'],
        ['unifier'],
        ['bid', 'value'],
        ['title1', 'value'],
        ['title2', 'value'],
        ['description', 'value'],
        ['link', 'value'],
      ],
      data: {
        minusWords: {
          value: '',
          maxLength: 20000,
          length: 0
        },
        companies: [],
        groups: [],
        unifier: [],
        region: {
          value: ''
        },
        bid: {
          value: ''
        },
        title1: {
          value: '',
          maxLength: 56,
          length: 0
        },
        title2: {
          value: '',
          maxLength: 30,
          length: 0
        },
        description: {
          value: '',
          maxLength: 81,
          length: 0
        },
        displayedURL: {
          value: '',
          maxLength: 20,
          length: 0
        },
        link: {
          value: '',
          maxLength: 1024,
          length: 0
        },
        quickLinks: {
          items: []
        },
        corrections: {
          items: []
        },
        files: [],
      }
    }
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.data.quickLinks.items.push({
        title: {
          value: '',
          maxLength: 30,
          length: 0
        },
        desc: {
          value: '',
          maxLength: 60,
          length: 0
        },
        link: {
          value: '',
          maxLength: 1024,
          length: 0
        },
      })
    }

    for (let i = 1; i <= 4; i++) {
      this.data.corrections.items.push({
        value: '',
        maxLength: 25,
        length: 0
      })
    }
  },
  mounted() {
    let data = localStorage.getItem('data')
    if (data) {
      this.data = JSON.parse(data)
      document.querySelector('.uniqueData').innerHTML = this.generateTable(this.data.unifier)

      if (this.data.files)
        document.querySelector('.files').appendChild(this.generateHtmlList(this.data.files))
    } else {
      document.querySelector('.uniqueData').innerHTML = this.generateTable([{ company: '', group: '', words: '' }])
    }
  },
  components: {
    Loading
  },
  watch: {
    data: {
      handler() {
        this.validateQuickLinks(this.getRange(0, 3), '.quicklink-message')
        this.validateQuickLinks(this.getRange(4, 7), '.quicklink-message1')
        this.validateCorrections()
      },
      deep: true
    }
  },
  methods: {
    saveData(e) {
      e.preventDefault()
      localStorage.setItem('data', JSON.stringify(this.data))
      this.$notify("Сохранено")
    },
    clearData(e) {
      e.preventDefault()

      let isDelete = confirm("Вы действительно хотите удалить все данные?");

      if (isDelete) {
        localStorage.removeItem('data')
        this.$notify("Удалено")
        this.$router.go()
      }
    },
    generate(e) {
      e.preventDefault()

      if (this.validateRequired()) {
        this.$swal('Заполнены не все обязательные поля')
        return false
      }

      this.saveData(e)

      this.isLoading = true

      axios
        .post('/api/generate', this.data)
        .then(response => {
          this.isLoading = false
          if (response.data.success === true) {
            this.$swal('Успешно!')
            document.querySelector('.files').innerHTML = ''
            document.querySelector('.files').appendChild(this.generateHtmlList(response.data.result.files))
            this.data.files = response.data.result.files
            this.saveData(e)
          } else {
            this.$swal('Не удалось сгенерировать файлы')
          }
        })
        .catch(e => {
          console.log(e)
          this.isLoading = false
        });

    },
    validateRequired() {
      return this.requiredFields.some(key => {
        let nestedVal = null;

        nestedVal = _.get(this.data, key);

        return nestedVal == '' || nestedVal == []
      })
    },
    validateQuickLinks(range, message) {
      let items = this.data.quickLinks.items,
        titleLength = 0,
        quickMessage = document.querySelector(message)

      range.forEach((key) => {
        if (items[key] !== undefined)
          titleLength += items[key].title.length
      })

      if (items[range[0]] != undefined)
        quickMessage.innerHTML = `Длина заголовков БС ${range[0] + 1}-${range[range.length - 1] + 1}: ${titleLength} (максимум 65).`

      if (titleLength >= 66) {
        quickMessage.classList.add('bg-danger')
      } else {
        quickMessage.classList.remove('bg-danger')
      }
    },
    validateCorrections() {
      let items = this.data.corrections.items,
        titleLength = 0,
        correctionsMessage = document.querySelector('.corrections-message')

      for (let i = 0; i < items.length; i++) {
        titleLength += items[i].value.length
      }

      correctionsMessage.innerHTML = `Длина уточнений: ${titleLength} (максимум 65).`

      if (titleLength >= 66) {
        correctionsMessage.classList.add('bg-danger')
      } else {
        correctionsMessage.classList.remove('bg-danger')
      }

    },
    pasteFromClipboard(e) {
      let data = e.clipboardData.getData('text')

      this.prepareUniqueData(data)

      document.querySelector('.uniqueData').innerHTML = this.generateTable(this.data.unifier)
    },
    clickClipboard() {
      try {
        navigator.clipboard.readText()
          .then(data => {
            this.prepareUniqueData(data)
            document.querySelector('.uniqueData').innerHTML = this.generateTable(this.data.unifier)
          })
          .catch(() => {
            this.$swal('Не удалось вставить данные. Используйте Ctrl+V')
          });
      } catch (err) {
        this.$swal('Не удалось вставить данные. Используйте Ctrl+V')
      }
    },
    clearUnifier(e) {
      e.preventDefault()

      this.data.unifier = []
      this.data.companies = []
      this.data.groups = []

      this.saveData(e)

      document.querySelector('.uniqueData').innerHTML = this.generateTable([{ company: '', group: '', words: '' }])
    },
    generateTable(data) {
      if (!data) return ''

      let table = '<table>'

      if (data) {
        data.forEach(el => {
          table += '<tr>'
          table += `<td>${el.company}</td>`
          table += `<td>${el.group}</td>`
          table += `<td>${el.words}</td>`
          table += '</tr>'
        })
      }
      table += '</table>'

      return table
    },
    prepareUniqueData(data) {
      if (data) {
        let lines = this.splitLines(data).filter(item => item != '')

        this.data.unifier = []
        this.data.companies = []
        this.data.groups = []

        for (let i = 0; i < lines.length; i++) {
          let row = lines[i].split('\t')

          if (row.length != 3) {
            this.$swal('Должно быть 3 колонки')
            break
          }

          if (!this.data.companies.includes(row[0]))
            this.data.companies.push(row[0])

          if (!this.data.groups.includes(row[1]))
            this.data.groups.push(row[1])

          this.data.unifier[i] = {
            company: row[0],
            group: row[1],
            words: row[2]
          }
        }
      }
    },
    addQuickLinks() {
      if (this.data.quickLinks.items.length != 4) return false;

      for (let i = 1; i <= 4; i++) {
        this.data.quickLinks.items.push({
          title: {
            value: '',
            maxLength: 30,
            length: 0
          },
          desc: {
            value: '',
            maxLength: 60,
            length: 0
          },
          link: {
            value: '',
            maxLength: 1024,
            length: 0
          },
        })
      }
    },
    charCount(e, field) {
      field.length = field.value.length
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getRange(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    },
    splitLines(data) {
      return data.split(/\r?\n/)
    },
    generateHtmlList(data) {
      let lst = document.createElement('ul');
      lst.classList.add('list-group')

      data.forEach(el => {
        let listItem = document.createElement('li'),
          listHref = document.createElement('a')

        listItem.classList.add('list-group-item')

        listHref.innerHTML = el
        listHref.href = el

        listItem.appendChild(listHref)
        lst.appendChild(listItem)
      })

      return lst
    }
  }
}
</script>
